.app {
  height: 100vh;

  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
  }
}
