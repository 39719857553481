.button {
  display: flex;
  justify-content: center;
  border-radius: 4px;

  color: #eeeeee;
  background-color: #fd7e14;
  transition: background-color .3s;

  padding: 8px;

  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
  }

  &:hover {
    background-color: #ca6410;
    border-color: #ca6410;
    color: #FFFFFF;
  }

  &.disabled {
    cursor: default;
    background-color: #eeeeee;
    color: #aaa;
  }

  &.outline {
    border-color: #CCCCCC;

    &:hover {
      border-color: #fd7e14;
      color: #fd7e14;
    }
  }
}
