.address {
  display: flex;
  flex-direction: column;
  min-width: 400px;

  .field {
    margin-bottom: 16px;

    span:first-child {
      opacity: .5;
    }
  }

  .action {
    display: flex;
    justify-content: center;

    margin-top: 16px;
  }

  @media screen and (max-width: 600px) {
    min-width: 300px;
  }
}
