.editIcon {
  cursor: pointer;
}

@import "../../mixins";

.home {
  @include centered;

  flex-direction: column;
  position: relative;

  .address {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);

    .wallet {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        opacity: .5;
      }

      img {
        width: 20px;
        margin-left: 8px;
        cursor: pointer;
      }

    }
  }

  .button {
    margin-top: 32px;
  }

  @media screen and (max-width: 600px) {
    span {
      font-size: 14px;
    }
  }
}
