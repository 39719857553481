.successCheckMark {
    height: 128px;
    width: 128px;
    border-color: #5c940d;
    background-color: rgba(#82c91e, 0.5);
    border-radius: 50%;

    display: flex;
    justify-content: center;

    img {
        width: 72px;
    }
}