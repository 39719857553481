.charge {
    display: flex;
    flex-direction: column;
    align-items: center;

    .qrCode {
        margin: 16px 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .infos {
            > * {
                padding: 8px 0;
            }

            .status {
                &.pending {
                    color: darkgoldenrod;
                }

                &.paid {
                    color: green;
                }

                &.rejected {
                    color: #c92a2a;
                }
            }
        }

        .timeout {
            display: flex;
            flex-direction: column;
            align-items: center;

            margin: 16px 0;

            .bar {
                height: 36px;
                width: 256px;
                border: 1px solid #000000;

                margin-bottom: 4px;

                .progress {
                    background-color: #82c91e;
                    height: 36px;
                }
            }

            span {
                margin-top: 4px;
            }
        }
    }
}
