@import "../../mixins";

.statement {
    @include centered;

    .content {
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
        display: flex;
        flex-direction: column;

        padding: 16px;

        overflow-y: auto;
        height: 50%;
        min-width: 25%;

        .total {
            margin: 8px;

            span {
                opacity: .5;
            }

            .totalValue {
                display: flex;
                align-items: center;

                margin-top: 8px;

                span {
                    opacity: unset;
                }


                img {
                    width: 20px;
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
        }

        .transactionDate {
            margin-top: 16px;

            .transactions {
                margin-top: 12px;

                .transaction {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    margin: 8px 0;
                    padding: 12px;

                    cursor: pointer;

                    .transactionInfo {
                        display: flex;
                        flex-direction: column;
                        margin-right: 64px;

                        span:last-child {
                            margin-top: 4px;
                            opacity: .5;
                        }
                    }

                    .time {
                        margin-left: 64px;
                    }

                    &.pending {
                        border: 1px solid #e67700;
                    }

                    &.rejected {
                        span {
                            color: #c92a2a;
                        }
                    }
                }
            }
        }

        .noTransactions{
            color: red;

            padding: 16px 8px;
        }
    }

    @media screen and (max-width: 600px) {
        //justify-content: unset;
        //align-items: unset;
        height: unset;

        span {
            font-size: 14px;
        }

        .transactionDate {
            .transactions {
                .transaction {
                    .time {
                        display: none;
                    }
                }
            }
        }

    }
}