.label {
  display: block;

  font-weight: 600;
  font-size: 12px;

  width: 100%;

  margin-bottom: 4px;

  opacity: .7;

  cursor: default;
  color: #000000;

  margin-left: 4px;
}

.field {
  margin: 8px 0;
  line-height: 12px;

  padding: 8px;

  border: 1px solid #000000;
  border-radius: 8px;

  transition: all 0.3s;
  background: unset;

  .input {
    display: flex;
    align-items: center;
  }

  .suffix,
  .prefix {
    color: #000000;
  }

  .prefix {
    margin-right: 8px;
  }

  &:focus-within,
  &.disabled {
    background: unset;
    border-radius: 8px;
    border-color: #000000;

    .label {
      opacity: 1;
    }
  }

  &:focus-within {
    .suffix,
    .prefix {
      color: #000000;
    }

    input, textarea {
      color: #000000;
    }

    .label {
      color: #000000;
    }
  }

  input, textarea {
    display: block;
    margin: 0;
    border: none;
    flex-grow: 1;

    outline: none;

    background: transparent none repeat scroll 0 0;

    font-size: 14px;
    line-height: 22px;
    color: #000000;

    &::placeholder {
      color: rgb(158, 158, 158);
    }
  }

  &.hasError {
    border-color: #c92a2a;
  }
}

.error {
  font-size: 10px;
  color: #c92a2a;
  margin-left: 4px;
}
