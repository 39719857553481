.receiptImage {
    margin: 16px 0;
    text-align: center;

    max-height: 60vh;
    overflow-y: auto;

    img {
        width: 256px;
        margin-bottom: 8px;
    }
}