.background {
  $padding: 24px;

  background-color: rgba(20, 20, 20, 0.8);
  @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, .8);
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  .dialog {
    background-color: #FFFFFF;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: $padding;
    border-radius: 4px;

    width: fit-content;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .title {
        display: flex;
        align-items: center;

        span {
          font-size: 28px;
          font-weight: bold;
        }

        .leading {
          margin-right: 8px;
        }
      }

      .close {
        cursor: pointer;

        &:hover {
          opacity: .8;
        }

        @media (prefers-color-scheme: dark) {
          filter: invert(0.7);
        }
      }
    }

    .subtitle {
      font-weight: 200;
    }
  }
}
