@import "../../mixins";
@import "../../variables";

@keyframes pan-in {
    0% {
        opacity: 0;
        transform: translateX(10%);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pan-out {
    0% {
        opacity: 1;
        transform: translateX(0);
        margin: 16px 0;
    }

    99% {
        opacity: 0;
        transform: translateX(10%);
        margin: 16px 0;
    }

    100% {
        opacity: 0;
        transform: translateX(10%);
        margin: 0;
    }
}

@keyframes pan-down {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pan-up {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    99% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 0;
        transform: translateY(-10%);
    }
}

@keyframes shrink {
    0% {
    }

    100% {
        padding: 0;
        font-size: 0;
        margin: 0;
        height: 0;
        width: 0;
    }
}

.alerts {
    position: absolute;
    overflow-x: hidden;
    top: 80px;
    right: 16px;

    z-index: 2;

    $tickAnimation: .5s;

    .alertItem {
        @include elevation;

        background-color: #CCCCCC;
        margin: 16px 0;

        display: flex;
        align-items: center;
        position: relative;
        min-width: 400px;

        border-radius: 4px;

        &.show {
            animation: pan-in $tickAnimation forwards;
        }

        &.hide {
            animation: pan-out $tickAnimation forwards;

            .icon, .info, img, &:before {
                animation-name: shrink;
                animation-delay: $tickAnimation;
                animation-duration: $tickAnimation;
                animation-fill-mode: forwards;
            }
        }

        > * {
            margin: 16px 0;
        }

        &:before {
            content: ' ';

            width: 3px;
            height: 56px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        img {
            @media (prefers-color-scheme: dark) {
                filter: invert(0.7);
            }
        }

        .icon {
            border: 1px solid;
            border-radius: 50%;
            position: relative;

            $sizeIcon: 32px;
            height: $sizeIcon;
            width: $sizeIcon;
            margin: 0 16px;

            > * {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .info {
            margin-right: 32px;

            :first-child {
                display: block;

                font-weight: 600;
                font-size: 1.1em;
            }

            :last-child {
                opacity: .8;
            }
        }

        .close {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
            margin: 0;
        }

        &.success {
            &:before {
                background-color: rgb(22, 132, 23);
            }

            .icon {
                border-color: rgb(22, 132, 23);

                img {
                    filter: invert(34%) sepia(62%) saturate(967%) hue-rotate(77deg) brightness(97%) contrast(91%);
                }
            }
        }

        &.error {
            &:before {
                background-color: #7e0d0d;
            }

            .icon {
                border-color: #7e0d0d;

                img {
                    filter: invert(14%) sepia(39%) saturate(7293%) hue-rotate(352deg) brightness(132%) contrast(95%);
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        top: 0;
        right: 0;

        .alertItem {
            min-width: unset;
            width: 100vw;

            margin: 0;

            > * {
                margin: 0;
            }

            &.show {
                animation: pan-down $tickAnimation forwards;
            }

            &.hide {
                animation: pan-up $tickAnimation forwards;
            }
        }

    }
}
