.loading {
    display: inline-block;
    position: relative;

    $size: 48px;
    width: $size;
    height: $size;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: $size;
        height: $size;
        border-style: solid;
        border-width: 2px;
        border-color: black transparent transparent transparent;
        border-radius: 50%;
        animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes loading-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}