.toolbar {
  display: flex;
  margin: 16px;

  .page {
    font-size: 17px;
    opacity: .5;

    cursor: pointer;

    &:not(:first-child) {
      margin-left: 16px;
    }

    &.selected {
      opacity: 1;
    }
  }
}