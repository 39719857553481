.content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .info {
        display: flex;
        flex-direction: column;

        > * {
            padding: 8px 0;

        }
    }
}

.actions {
    display: flex;
    justify-content: end;
    margin: 24px 8px 0 0;

    > *:not(:first-child) {
        margin-left: 8px;
    }
}