@mixin centered {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
}

@mixin elevation {
    @media (prefers-color-scheme: light) {
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
    }
}
